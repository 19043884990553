import { Route, Routes } from "react-router-dom";
import "./App.css";
import { About } from "./pages/about/About";
import { Contact } from "./pages/contact/Contact";
import Nav from "./pages/nav/Nav";
import Work from "./pages/work/Work";
import WorkItem from "./pages/workitem/WorkItem";
function App() {
  return (
    <div className="App">
      <div className="container">
        <Nav />
        <Routes>
          <Route path="/" element={<Work />} />
          <Route path="/work" element={<Work />} />
          <Route path="/work/:id" element={<WorkItem />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<p>Page not found</p>} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
