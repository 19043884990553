import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  dooda1,
  dooda2,
  dooda3,
  doodacover,
  fan1,
  fan10COver,
  fan10COver2,
  fan2,
  fan4,
  fan44,
  fan5,
  fan6,
  fan7,
  fan8,
  g2,
  g3,
  g4,
  g5,
  g6,
  g7,
  gcover,
  pir1,
  pir2,
  pir3,
  pir4,
  pir5,
  pir6,
  pir7,
  pir8,
  pircover,
  tye1Cover,
  tye1Cover2,
  tye2,
  tye3,
  tye4,
  tye5,
  urb0,
  urb1,
  urb2,
  urb3,
  urb4,
  urbcover,
} from "../../image/allImage.js";
import "./work.scss";

const projects = [
  [
    {
      id: 5,
      name: "Piranha",
      des: `Piranha is a starting real estate company, the founders of the company tasked us to create the brand identity for
      the company, the logo inspired from piranha fish to deliver the message that this company was created to swallow the
      real estate market, and the project including the designs for business cards and the company's paper work.`,
      cover: "1By1vpI2BoOvhkPfBtFEAOO7Rn61pAdqR",
      images: [
        "1sXUy2XLJi8s1clD2hdEuXQdBQz6yiNgJ",
        "1xWtPzBGfVm6cVeaquzF29-n6JDJUcSo2",
        "16rbNfamFZ44ZdHyG-W-ZuXWCCo839amf",
        "1XZZRB58xJcS34k3-qfBpfRe925WptR7d",
        "17coMNqwhfGLSFTGJGVHH3V0xE7MNLZBQ",
        "1Lds5875Ka8_pSoCIz_MhrcRm1VfI4RG8",
        "1vgFAxFkGrRu6RZ_d8L5vrV57wtx4-nSp",
        "1QFEqb5zqkej6ev_bPA0-8QxB2tNT5D4V",
        "1h-_11_6nuet3ku8-xHpHI3I4cqrPWsvt",


      ],
    },

    {
      id: 2,
      name: "Tiye",
      des: `Tiye jewels is a starting Egyptian accessories brand, the name Tiye is inspired from the Pharaonic queen Tiye,
      the brand specialized in accessories and handmade jewels, the brand owners tasked us to create brand identity
      and the packaging of the products including bags, envelopes, cards and stamp.`,
      cover: '1c9HABTw1gCgg8hY2pT1LE-vxhzmXYQwC',
      images: [
            "1H0DUNejQ9hwAzsBb_EtovFf7XF54Lx1K",
            "11HAEkbG97QXqyjBbkaUA9a_S2lUHMKKo",
            "18MqfzFa-4322rC0Ck6HvNez37QFwbiNE",
            "17rBo8p4MmrY8LhPzMwkE49l0NPitda47",
            "1_qjubAM3KDXPco63Qq-6DZg0PIvm1Ax0",
            "1Gvv3D_hbD9pWAe9odv55lTWVElNjJyeA",
            "1o6nBM53FsW0YbtuqmhUHRzWSNRDkjzgP"
      ],
    },
    {
      id: 3,
      name: "Fanakeko",
      des: `Fanakeko is a restaurant specialized in Egyptian shawerma, the restaurant owners tasked us to create brand identity of 
      the restaurant, this is a full project including logo, menu, packaging, banners, tissue papers, pins and signs, The logo
      inspired from shawerma skewer with flames from both sides to show the quality of our fresh product.`,
      cover: "1QpN1Ik9NieBfRL2CvtfFkqbbGzdtRMkA",
      images: [
         "1RoOTt-cSxyqaLzYjeCP7F7W6W6DHvqCf",
         "1KvomYBMReBEJMukm9b79YgnTf5MnV2Fg",
         "1etmoofSwwx5u-VxztrPwtJHviQrL9lwO",
         "1Ij_0IleBKdKboJpnO-Cl7uPJKbYNY3Ov",
         "10qtZ5xSQdSLo8PYLloxLSMEus_xBQ95b",
         "1-g5DRnVCYl1xyalefmMkGv3esIEY16Wf",
         "1WVtnC5qBo6jT_8k3xyScAcj8ewhxMnfc"
      ],
    },
  ],
  [
    {
      id: 1,
      name: "Urbanwave",
      des: `Urbanwave is an Egyptian fashion brand launched in 2021, the brand owners tasked us to create the brand identity and 
      also the packaging for their products, they tasked us to create the brand logo and the packaging including box, cards
      clothes tags. the brand specialized in streetwear clothes and the brand identity designed to show that.`,
      cover: "1t33VtFrTYBSk9Oz_88FAhOAedmhofBLc",
      images: [
         "17W9uUfq6Ne6Z8DN3nwHK9mTv22QktOzn",
         "1CqoK9lKMKmmJa_9ES2vdGiAow2WYa4sM",
         "14J6AyhFz-NEbqBSX7uyBjn1-1D6QqetK",
         "1lg0s0q1UAtYvdVJk8e6e3othk7zlhp21",
         "1vdAYTy4g9qPWYmyjTRAq5GCpPx2ThE0y",
         "1X6x4vZGX2Ashk9SdOp0zhylPv1IvnwED"
        

      ],
    },
    {
      id: 4,
      name: "Dooda's Kitchen",
      des: `Dooda's kitchen is a homemade food project specialized in homemade and oreintal meals, deserts and
      frozen products. the owners tasked us to create brand identity, menu and packaging for meals.`,
      cover: "1BXPsW1msvcqXlr4r0nZb6NLA0_jlvgzN",
      images: [
        "1NYyAUYmhkkjayA-vkWf7xV6z-EF2-_3e",
        "1eSkbODtvQUnANj9LbXpei1seYF0DdPgf",
        "1Po5bfz_PWgYHMoScz7XYkQCXBaIxeARm",
        "1gTD7RHVRxqpIFgvKF99gVIs7ij175PGS",
        "1BGcytTFc5j_gRdH08UQlEfDWcUBufbUG",
        "1DWPoJgYyIZIyFi26tDmNOiL1NxORoG4N",
        "1ba-qAw2WmQjzzbZ355yuTeq8OzDuBniB",
        "1K0rVTY8Vtar6hzXMf66WeepsWGXqJz0X",

      ],
    },
    {
      id: 6,
      name: "Gloss",
      des: `Gloss is a jewelry project specialized in silver jewelry, the brand owners tasked us to create the brand identity
      and the packaging, the project including logo, bag, jewelry box and cards.`,
      cover: "11UDFlt0Z-5P277IYqhHDDKY5bbnU63Pv",
      images: [

        "1u4KOX2V-EactGy-VDO0Z_65kLt4a4mpv",
        "16rwqTaaFz43DtCHYYFvgxXbLmfyR-Zif",
        "1WSclDMaOXlHbFW7mMBWuyXEJMJAU0Rh0",
        "1NiVLetpPlFy2eTUHoCrNr5bhQ4omApAb",
       "1JGedRtOAh5eYNKSV-Cz2Lac1ebzedsVX"


      ],
    },
  ],
  [ 
    {
    id: 7,
    name: "Label",
    des: `Label is a local fashion brand in Egypt, they tasked us to make some illustrations
    to be printed on hoodies collection. all illustrations and designs are made based on 
    common vision between us and the owners.`,
    cover: "1TM5X4fi9yiYFXHbSLbOKbhND2FbULpkK",
    images: [
       "1LnquDuJmTtsYQUKBkPvQvHzxbJV56uFF",
       "1tNTWsQ7G47wQ3Ru_2OMynSnPe3Q0NvDv",
       "19S2coCNtESXnAEDjuo6_mujo0HG4Mr4i",
       "145A46uTHctl9VOWDo5b8VQOUhvSRc5qd",
       "1jkhlImJ3GuM6JjXbhr5e3_xzBmEUp7xk",
       "1WDfxIpGtwUcYIg9p9ArlF6o1PrjEeEl1"
      


    ],
  },
  {
    id: 8,
    name: "Logos",
    des: `A collection of various brand logos created throughout the years.`,
    cover: "1XMXFpnpwDA0OBwW0GvONC2V6hWwHmetQ",
    images: [
      "1tfHJyxPNbdvrZ3wksS9F_pYUuz6Ni5q6",
"1n6gZm_O3KsiW-M1lqS9U_YW_MiQo1pPv",
"12WZXlTBbNjCXGQsBgeL38epJhCS4TN5B",
"1sno_al38VkAEJ8nE6iLRegPUdfDbSW1-",
"1iRnJRCcxNUSK-3KV37wiDqrUiyW26OOh",
"1-RCWzPDyleQ9aOH2IxgBGPZ7Uj5UqXHP",
"1gmalp38JkeYgMeFx7CRB-QPn9N7lv_hK",
"15ReC-OKbMIUwuvYq2XMpVVprzcGPPaeQ",
"1UpqxKipWjBE3o27x46NNzc54kBu0V-eg",
"P1rI4i-7ZXH6gnmC3AcqgAdq0HUCS_98zG",
"P1Nqo2cmv9UImggn-B1JZ7Oym_TJjPHhPO",


    ],
  },
  {
    id: 9,
    name: "Ajenda",
    des: `Ajenda is a starting online gift brand in Sultanate of Oman, the brand owner asked us
    to create the brand identity, the project includes logo design and identity, packaging,
    designs for products and social media designs`,
    cover: "1BGpncMSCtEKsoMA9rcz2qHg5T863PgDQ",
    images: [
      "1CQh2Y1HIKVgjPswZpvZ1iI75HoRPjfVo",
"1ejh-6VCnFExZjFjDoR6iJnFl7t6Gjtxk",
"152n549FTkpvZ-GtebLaQ5IkYu_vysM8n",
"1nXAHUZO8LMWBe9Folo2RrHIZv2jaK4RK",

    ],
  },
]
];

const Work = () => {
  const navigate = useNavigate();

  const navigateLink = (id) => {
    navigate(`/work/${id}`);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="work_section">
      {/*  */}

      {/*  */}
      {projects.map((it, i) => {
        if (it.length == 3) {
          return (
            <div className="item" key={i}>
              <div className="large" onClick={() => navigateLink(it[0].id)}>
                <img src={`https://drive.google.com/uc?export=view&id=${it[0].cover}`} alt={it[0].name} />
                <div className="overlayer">
                  <h4>{it[0].name}</h4>
                </div>
              </div>
              <div className="small">
                <div className="img1" onClick={() => navigateLink(it[1].id)}>
                  <img src={`https://drive.google.com/uc?export=view&id=${it[1].cover}`} alt={it[1].name} />
                  <div className="overlayer">
                    <h4>{it[1].name}</h4>
                  </div>
                </div>
                <div className="img2" onClick={() => navigateLink(it[2].id)}>
                  <img src={`https://drive.google.com/uc?export=view&id=${it[2].cover}`} alt={it[2]?.name} />
                  <div className="overlayer">
                    <h4>{it[2]?.name}</h4>
                  </div>
                </div>
              </div>
            </div>
          );
        } else if (it.length == 2) {
          return (
            <div className="item" key={i}>
              <div className="large-mid" onClick={() => navigateLink(it[0].id)}>
                <img src={`https://drive.google.com/uc?export=view&id=${it[0].cover}`} alt={it[0].name} />
                <div className="overlayer">
                  <h4>{it[0].name}</h4>
                </div>
              </div>
              <div className="small-mid">
                <div className="img1" onClick={() => navigateLink(it[1].id)}>
                  <img src={`https://drive.google.com/uc?export=view&id=${it[1].cover}`} alt={it[1].name} />
                  <div className="overlayer">
                    <h4>{it[1].name}</h4>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="item" key={i}>
              <div
                className="large-only"
                onClick={() => navigateLink(it[0].id)}
              >
                <img src={`https://drive.google.com/uc?export=view&id=${it[0].cover}`} alt={it[0].name} />
                <div className="overlayer">
                  <h4>{it[0].name}</h4>
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default Work;
