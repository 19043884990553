import "./contact.scss";

import { AiOutlineMail } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";

import { FaTwitterSquare } from "react-icons/fa";
import { IoLogoBehance } from "react-icons/io5";
import { SiInstagram } from "react-icons/si";
export const Contact = () => {
  return (
    <div className="conatct_wrap">
      <p>Get in touch.</p>
      <p>Let's work together.</p>

      <div className="social">
        <a href="mailto:kspiritx98@gmail.com"  rel="noreferrer">
          <AiOutlineMail />
        </a>
        <a href="https://www.facebook.com/hussien.mohamed.52" target="_blank" rel="noreferrer">
          <BsFacebook />
        </a>

        <a href="https://twitter.com/HusienMuhamed" target="_blank" rel="noreferrer">
          <FaTwitterSquare />
        </a>
        <a href="https://www.instagram.com/kspiritx/" target="_blank" rel="noreferrer">
          <SiInstagram />
        </a>
        <a href="https://www.behance.net/hussienmohamed6" target="_blank" rel="noreferrer">
          <IoLogoBehance />
        </a>
      </div>
    </div>
  );
};
