import { useState } from "react";
import { CgMenu } from "react-icons/cg";
import { FaWindowClose } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./nav.scss";

const Nav = () => {
  const [openNav, setopenNav] = useState(false);
  return (
    <nav>
      <div className="nav_content">
        <div className="logo">
          <p>HUSSIEN </p>
          <h6> Illustrator and Graphic Designer</h6>
        </div>
        <div className="nav_links">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="work">Work</Link>
            </li>
            <li>
              <Link to="about">About</Link>
            </li>
            <li>
              <Link to="contact">Contact</Link>
            </li>
          </ul>
        </div>
        <CgMenu onClick={() => setopenNav(!openNav)} />
      </div>
      <div className={`small_menu ${openNav ? "" : "close"}`}>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="work">Work</Link>
          </li>
          <li>
            <Link to="about">About</Link>
          </li>
          <li>
            <Link to="contact">Contact</Link>
          </li>
        </ul>
        <FaWindowClose onClick={() => setopenNav(!openNav)} />
      </div>
    </nav>
  );
};

export default Nav;
