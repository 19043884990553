import "./about.scss";

export const About = () => {
  return (
    <div className="about_wrap">
      <div className="title">
        <p className="">About</p>
      </div>
      <div className="wrap">
        <p>
        I'm Hussien, Graphic designer and illustrator specialized in branding, brand identity and packaging.
        the main purpose is to create meaningful and timeless design for our clients and their audience.
        </p>
        <p>
        The goal is to create a great relations with our clients, constant communication is our way to 
keep our clients informed of every update till we have the required design.
        </p>
      </div>
    </div>
  );
};
